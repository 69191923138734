// src/components/CTA.js
import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ctaBg } from "../assets";
import Grow from "@mui/material/Grow";
import { Link } from "react-router-dom";

function CTA() {
  return (
    <div sx={{ backgroundColor: "primary.dark" }}>
      <Container maxWidth="lg">
      <Typography variant="h1" className="gradient-text" sx={{ fontWeight: 900, fontSize: { xs: '2rem', md: '4rem' }, textAlign: 'center' }}>
          Welcome to Dynavertmood
        </Typography>
        <Typography variant="h4" className="gradient-text" sx={{ fontWeight: 900, fontSize: { xs: '1rem', md: '1.5rem' }, fontStyle: 'italic', textAlign: 'center', marginBottom: '2rem' }}>
          Discover a World of Free Online Games
        </Typography>
        <Typography variant="h6" sx={{ textAlign: 'center', marginBottom: '2rem' }}>
          Our collection includes fantasy games, puzzle games, sports games, adventure games, and more. Enjoy a wide variety of games without any charges or the involvement of real money. Practicing gaming on our platform is purely for fun and does not imply future success in real money gambling. This website is intended for persons over 18 years of age and is for entertainment purposes only.
        </Typography>
        
        <Box sx={{ marginBottom: '2rem' }}>
          <Typography variant="h2" sx={{ fontWeight: 700, textAlign: 'center', marginBottom: '1rem' }}>
            Explore Our Games
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
            <Box sx={{ width: { xs: '100%', sm: '48%', md: '30%' }, marginBottom: '1rem' }}>
              <Typography variant="h4" sx={{ fontWeight: 600 }}>Fantasy Games</Typography>
              <Typography variant="body1">Dive into mystical worlds filled with magic and wonder. From enchanted forests to mythical creatures, our fantasy games will transport you to another realm.</Typography>
            </Box>
            <Box sx={{ width: { xs: '100%', sm: '48%', md: '30%' }, marginBottom: '1rem' }}>
              <Typography variant="h4" sx={{ fontWeight: 600 }}>Puzzle Games</Typography>
              <Typography variant="body1">Challenge your mind with our selection of puzzle games. Perfect for those who love to think critically and solve complex problems.</Typography>
            </Box>
            <Box sx={{ width: { xs: '100%', sm: '48%', md: '30%' }, marginBottom: '1rem' }}>
              <Typography variant="h4" sx={{ fontWeight: 600 }}>Sports Games</Typography>
              <Typography variant="body1">Get your adrenaline pumping with our sports games. From soccer to racing, experience the thrill of competition right from your screen.</Typography>
            </Box>
            <Box sx={{ width: { xs: '100%', sm: '48%', md: '30%' }, marginBottom: '1rem' }}>
              <Typography variant="h4" sx={{ fontWeight: 600 }}>Adventure Games</Typography>
              <Typography variant="body1">Embark on epic journeys and uncover hidden treasures. Our adventure games are designed for those who crave excitement and exploration.</Typography>
            </Box>
            <Box sx={{ width: { xs: '100%', sm: '48%', md: '30%' }, marginBottom: '1rem' }}>
              <Typography variant="h4" sx={{ fontWeight: 600 }}>Educational Games</Typography>
              <Typography variant="body1">Learn while you play with our educational games. Ideal for kids and adults alike, these games make learning fun and interactive.</Typography>
            </Box>
          </Box>
        </Box>
        
        <Box sx={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Typography variant="h2" sx={{ fontWeight: 700, marginBottom: '1rem' }}>Why Choose Dynavertmood?</Typography>
          <Typography variant="body1" paragraph>Free to Play: All our games are completely free. Enjoy endless entertainment without spending a dime.</Typography>
          <Typography variant="body1" paragraph>Fair Play Guaranteed: We use certified random number generators to ensure fair and transparent gameplay. Trust and integrity are at the heart of our platform.</Typography>
          <Typography variant="body1" paragraph>No Real Money Involvement: Our games do not involve real money. Practice gaming for fun without any financial risk.</Typography>
          <Typography variant="body1" paragraph>Community Driven: Join a vibrant community of gamers and developers. Share your experiences, provide feedback, and help us grow together.</Typography>
        </Box>
        
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h2" sx={{ fontWeight: 700, marginBottom: '1rem' }}>Join Us Today</Typography>
          <Typography variant="body1" paragraph>Ready to dive into the fun? Start playing now and discover why Dynavertmood is the ultimate online playground. If you have any questions or feedback, feel free to reach out to us at info@dynavertmood.com. Let the games begin!</Typography>
        </Box>
      </Container>
    </div>
  );
}

export default CTA;
