import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { ctaBg } from "../assets";
import { Button } from "@mui/material";
import { BsDot } from "react-icons/bs";

function Copyright() {
  return (
    <Typography variant="body1">
      {"Copyright © "}
      dynavertmood {new Date().getFullYear()}
      {"."} <br />
      The Services are virtual pokies-style entertainment games, and do not
      involve any real money bets or prizes.
    </Typography>
  );
}

function PlayResponsible() {
  return (
    <Typography variant="body1" sx={{ textAlign: 'center' }}>
  Disclaimer: Dynavertmood is a free online gaming platform intended for entertainment purposes only. No real money is involved in any of our games. The games on this website do not offer real money gambling or an opportunity to win real money or prizes. Users must be 18 years or older to access our games. Practicing gaming on our platform does not imply future success in real money gambling. By using this website, you agree to our terms and conditions and privacy policy.
</Typography>

  );
}

export default function StickyFooter() {
  return (
    <Box
      component="footer"
      sx={{
        px: 2,
        mt: "auto",
        borderTop: "1px solid #1f2031",
        backgroundColor: "primary.main400",
      }}
    >
      <Container
        maxWidth="xl"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          padding: "2rem",
        }}
      >
        <div>
          <Button color="inherit" component={Link} to="/contacts">
            Contacts
          </Button>
        </div>
        <Typography variant="body1">
          <Link
            to="/terms"
            style={{ color: "#ffffff", textDecoration: "none" }}
          >
            Terms
          </Link>{" "}
          <BsDot style={{ verticalAlign: "middle" }} />{" "}
          <Link
            to="/privacy"
            style={{ color: "#ffffff", textDecoration: "none" }}
          >
            Privacy Policy
          </Link>
        </Typography>
        {/* <Copyright /> */}
      </Container>
      <Box
        maxWidth="md"
        mx="auto"
        style={{
          textAlign: "center",
          borderTop: ".1px solid #000000",
          padding: "2rem 0",
        }}
      >
        <PlayResponsible />
      </Box>
    </Box>
  );
}
