// src/components/Hero.js
import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { hero } from "../assets";
import Grow from "@mui/material/Grow";

function Hero() {
  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      style={{
        backgroundColor: "#333652",
        background: `radial-gradient(circle, rgba(0,0,0, .7) 0%, rgba(0,0,0,.7) 100%), url(${hero})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top",
        backgroundAttachment: "fixed"
      }}
    >
      <Grow in={true} timeout={1200}>
        <Box
          margin="0 auto"
          maxWidth="lg"
          p={4}
          display="flex"
          height="100%"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Typography variant="h1"  style={{ fontWeight: "900" }} >
          Discover a world where gaming and fun unite

          </Typography>         
        </Box>
      </Grow>
    </Box>
  );
}

export default Hero;
