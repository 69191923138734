import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      dark: "#200347",    // Deep black, used for darkest elements like backgrounds
      main: "#3b045d",    // Rich primary red, vibrant and eye-catching
      main600: "#38045dd2", // Slightly lighter and more vivid red
      main500: "#520379b7", // Mid-tone between main and main600
      main400: "#490992b7", // A softer version of main600
      main300: "#5d0bafb7", // Lighter, softer red, good for hover states
      main200: "#730baf92", // Very light red, used for accents and highlights
      main100: "#830baf69", // Near pastel shade, used for backgrounds and large areas
      main50: "#7809c74d",  // Very light pastel red, almost pink
  },
  text: {
      primary: "#EEEEEE", // Light grey for high contrast with dark backgrounds
  },
  secondary: {
      main: "#02fff2",   // Dark shade reused for secondary elements
      secondary: "#EEEEEE", // Light grey reused for less emphasized text or elements
  },  
    success: {
      main: "#C8E4B2",
    },
  },
  typography: {
    fontFamily: "Chakra Petch, sans-serif",
    body1: {
      fontSize: "18px",
    },
    h1: {
      fontSize: "3rem",
      "@media (min-width:600px)": {
        fontSize: "6rem",
      },
    },
    h2: {
      fontSize: "2rem",
      "@media (min-width:600px)": {
        fontSize: "4rem",
      },
    },
    h3: {
      fontSize: "1.7rem",
      "@media (min-width:600px)": {
        fontSize: "3rem",
      },
    },
    h4: {
      fontSize: "1.4rem",
      "@media (min-width:600px)": {
        fontSize: "2rem",
      },
    },
  },
  spacing: [0, 4, 8, 16, 32, 64],
});

export default theme;
