import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function CookieModal() {
  const [showCookieModal, setShowCookieModal] = useState(false);
  const [cookieAccepted, setCookieAccepted] = useState(false);

  useEffect(() => {
    // Check if the user has already accepted cookies
    const cookieAccepted = localStorage.getItem("cookieAccepted");
    if (!cookieAccepted) {
      setShowCookieModal(true);
    } else {
      setCookieAccepted(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    // Set a cookie to remember that the user has accepted cookies
    localStorage.setItem("cookieAccepted", true);
    setCookieAccepted(true);
    setShowCookieModal(false);
  };

  return (
    <div>
      {showCookieModal && !cookieAccepted && (
        <div className="cookie-container">
        <div className="cookie-block">
          <p style={{fontStyle: "italic"}}>Before you start enjoying the variety of games we offer (collectively referred to as the "Services"), it is important that you carefully read and agree to the following terms and conditions:</p>
          <p><b>Non-Gambling Nature of Services:</b> We emphasize that dynavertmood.com is not a gambling operator and does not involve the use of Real Money. Our Services are designed solely for entertainment purposes and are not to be used for real-money gambling. We offer virtual entertainment games only.</p>
          <p style={{fontStyle: "italic"}}>By using dynavertmood.com, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.</p>
                    
          <button onClick={handleAcceptCookies} className="cookie-accept">
            Accept{" "}
          </button>
          <Link to="privacy" className="cookie-more">
            Learn more{" "}
          </Link>
          {/*         
          <div className="cookie-close-wrap close-btn">
            <button
              onClick={() => setShowCookieModal(false)}
              className="cookie-close-btn"
            >
              <svg
                height="25px"
                width="25px"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div> */}
        </div>
        </div>
      )}

    </div>
  );
}
